.spacing_sec {
    padding-bottom: 20px;
}

.main_inner_sec {
    .header-left {
        display: flex;
        align-items: center;
        justify-content: space-between;



        .link-copy {
            width: 100%;
            display: flex;
            align-items: center;
            margin-right: 10px;


            input {
                text-align: center;
                font-size: 15px;
                margin-bottom: 0px;
                padding: 10px;
                border-radius: 5px 0 0 5px;
                width: 100%;
                border: 1px solid var(--border-color);
                background: var(--txt-white);
                box-shadow: var(--box-shadow);


            }

            .icon-copy {
                background: var(--sky-blue);
                padding: 5px 11px;
                border: 1px solid var(--border-color);
                border-radius: 0 5px 5px 0;
                border-left: none;
                cursor: pointer;

                svg {
                    font-size: 32px;
                    color: var(--txt-white);
                }
            }
        }

        @media ($breakpoint_MD) {
            margin-top: 30px;
            display: flex !important;
        }

        p {
            font-size: 14px;
            margin-bottom: 0px;
            background: var(--bodybg);
            padding: 10px;
            border-radius: 5px;
            // width: 80%;
            border: none;
            margin-right: 10px;

            span {
                svg {
                    color: var(--sky-blue);
                }
            }
        }

        .scan_code {
            width: 38px;
            border: 1px solid var(--sky-blue);
            border-radius: 5px;
        }



        .icons_im {
            display: flex;

            svg {
                font-size: 38px;
                color: var(--primary-color);
                margin-left: 10px;
            }
        }
    }

    @media ($breakpoint_MD) {
        .header-left {
            display: none;
        }

    }

    .banner-section-top {

        border-radius: 10px;
        margin-top: 30px;

        // .banner_row {
        //     @media ($breakpoint_SM) {
        //         flex-direction: column !important;
        //     }
        // }

        .logo_sec {
            display: flex;
            gap: 10px;
            align-items: center;
            text-align: center;

            @media ($breakpoint_MD) {
                flex-direction: column;
                justify-content: center;
                margin-bottom: 15px;
            }

            br {
                display: none;

                @media ($breakpoint_SM) {
                    display: none;
                }

                @media ($breakpoint_450) {
                    display: block;
                }
            }

            p {
                margin: 0px;
                color: var(--sky-blue);
                background: var(--light-blue);
                padding: 10px;
                border-radius: 10px;

                @media ($breakpoint_MD) {
                    width: 80%;
                }

                span {
                    color: var(--txt-black);
                }
            }

            img {
                width: 54px;
            }
        }

        .ranks {
            display: flex;
            gap: 15px;
            justify-content: center;

            .rank1 {
                text-align: center;

                p {
                    color: var(--sky-blue);
                }
            }
        }

        .buttons_new {
            display: flex;
            justify-content: flex-end;
            gap: 10px;

            .button_style {
                white-space: nowrap;

                @media ($breakpoint_450) {
                    width: 100%;
                    margin-bottom: 10px;
                    margin-top: 0;
                }
            }

            @media ($breakpoint_MD) {
                margin-bottom: 20px;
                margin-top: 10px;
                justify-content: center;
            }
        }

        .banner-img {
            border-radius: 10px;
            width: 100%;
        }

    }

    .cards_row {
        padding: 30px 0px 0px 0px;

        .card_inner {
            overflow: hidden;
            border: none;
            background: var(--new-card-bg);
            border-radius: 10px;
            margin-bottom: 20px;
            position: relative;
            z-index: 1;
            border: 1px solid #dddddd21;

            @media ($breakpoint_MD) {
                min-height: 120px;
            }

            @media ($breakpoint_SM) {
                min-height: auto;

            }

            &:hover {

                img {
                    transform: scale(1.1);

                }



            }

            .card-body {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .card-text {
                    font-weight: 600;
                    font-size: 11px;
                    color: var(--txt-black);
                    text-align: right;

                }

                .card-title {
                    transition: all 0.4s linear;
                    color: var(--txt-light);
                    text-align: right;
                    margin-bottom: 34px;

                }
            }

            .card_img_outer {
                margin-left: 10px;
                border: 2px dashed var(--sky-blue);
                padding: 5px;
                border-radius: 50%;
                background: var(--light-blue);



                img {
                    transition: all 0.4s linear;
                    width: 55px;
                    height: 55px;
                    background: var(--sidebar-bg);
                    border-radius: 50%;
                    border: 1px dotted var(--primary-color);
                    padding: 5px;
                }
            }

            &::after {
                content: '';
                content: "";
                position: absolute;
                height: 50%;
                width: 1100%;
                left: 0%;
                /* clip-path: polygon(-21% -367%, -1% -149%, 0% 92%, 106% 96%); */
                background: var(--primary-color);
                z-index: -1;
                opacity: 0.6;


            }
        }
    }

    .profit_cards {
        padding: 10px 0px 0px 0px;



        .card_1 {
            background: var(--primary-color);



            .card-title {
                color: var(--txt-white) !important;
            }

            h6 {
                color: var(--txt-white);
            }

            .icon_profit {
                img {
                    background: var(--txt-white) !important;


                }
            }
        }

        .card_2 {
            position: relative;
            z-index: 1;
            background: var(--sidebar-bg);

            &::after {
                content: "";
                position: absolute;
                height: 100%;
                width: 100%;
                background: url(../Image/card_bg_new.png);
                z-index: -1;
                opacity: 0.3;
                right: 0;
                border-radius: 10px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                display: none;
            }

            .icon_profit {
                img {
                    background: var(--primary-color) !important;

                }
            }
        }

        .card_3 {
            position: relative;
            z-index: 1;
            background: var(--sidebar-bg);

            &::after {
                content: "";
                position: absolute;
                height: 100%;
                width: 100%;
                background: url(../Image/card_bg_new.png);
                z-index: -1;
                opacity: 0.3;
                right: 0;
                border-radius: 10px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                display: none;
            }

            .icon_profit {
                img {
                    background: var(--primary-color) !important;
                }
            }
        }

        .card_4 {
            position: relative;
            z-index: 1;
            background: var(--sidebar-bg);

            &::after {
                content: "";
                position: absolute;
                height: 100%;
                width: 100%;
                background: url(../Image/card_bg_new.png);
                z-index: -1;
                opacity: 0.3;
                right: 0;
                border-radius: 10px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                display: none;
            }

            .icon_profit {
                img {
                    background: var(--primary-color) !important;
                }
            }
        }

        .profit-sec-row {
            row-gap: 28px;

            @media ($breakpoint_LG) {
                margin-top: 20px;
            }
        }

        .profit_inner_card {
            position: relative;
            z-index: -3;
            border: none;
            padding: 0;
            border: 1px solid #dddddd21;
            background: #fff;
            backdrop-filter: blur(12px);


            .card-body {
                display: flex;
                justify-content: space-between;
                flex-direction: row-reverse;
                align-items: center;
                padding: 14px 20px;

                .icon_profit {
                    // padding: 20px;

                    .profit {
                        width: 54px;
                        padding: 10px;
                        border-radius: 5px;
                        background: var(--light-blue);
                    }

                    &::after {

                        content: "";
                        position: absolute;
                        height: 50px;
                        width: 50px;
                        right: 3%;
                        top: 36%;
                        /* clip-path: polygon(50% 10%, 100% 0%, 100% 100%, 0% 100%, 0% 0%); */
                        /* border-bottom: 5px solid #2b3892; */
                        background-color: #31bafd;
                        border-radius: 5px;
                        z-index: -1;
                    }
                }

                .card_data {
                    .card-title {
                        color: var(--sky-blue);
                    }

                    .card-text {
                        color: var(--txt-light);
                    }

                    h6 {
                        color: var(--txt-black);
                    }

                }

            }
        }

        .last-row-profilt {

            .profit_inner_card {
                margin-bottom: 0 !important;
            }

        }

        .total_limit {
            background: #fff;
            border: 1px solid #dddddd21;
            margin-bottom: 20px;

            @media ($breakpoint_MD) {
                margin-bottom: 30px;
            }

            .card-body {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 100px;

                @media ($breakpoint_MD) {
                    flex-direction: column;
                    gap: 10px;
                }


                p {
                    width: 70%;
                    border: none;
                    background: var(--bodybg);
                    border-radius: 10px;
                    padding: 7px 10px;
                    margin: 0;
                    color: var(--txt-black);
                }

                h6 {
                    color: var(--sky-blue);
                    margin-bottom: 0;
                    white-space: nowrap;
                }

            }
        }

        .card_inner {
            border: none;
            height: 100%;
            background: var(--primary-color);
            border: 1px solid #dddddd21;

            .card-body {
                .data {
                    font-size: 12px;
                    color: var(--txt-white);
                }

                .profit_head {
                    display: flex;
                    justify-content: space-between;
                    color: var(--txt-white);
                }

                .trade_head {
                    display: flex;
                    justify-content: space-between;
                    background: #ffffff3b;
                    padding: 10px;
                    border-radius: 3px;
                    margin: 10px 0px;
                    align-items: center;
                    color: var(--txt-white);

                    h6 {
                        margin-bottom: 0px;
                    }

                    .arrow_up {
                        color: green;
                    }

                    .amount {
                        color: green;
                        margin-right: 8px;
                    }

                    .arrow_down {
                        color: red;
                    }

                    .amount_new {
                        color: red;
                        margin-right: 8px;
                    }
                }
            }
        }
    }

    .rank_royality {
        background: var(--card-new-df);
        padding: 20px;
        border-radius: 5px;
        margin: 30px 0px 0px 0px;
        border: 1px solid #dddddd21;


        h4 {
            margin-bottom: 10px;
            color: var(--sky-blue);
        }

        .rank_card {


            border-left: 8px double var(--sky-blue);
            color: var(--txt-white);
            background: var(--primary-color);

            border-right: 8px double var(--sky-blue);

            .card-body {
                display: flex;

                .executive_icon {
                    svg {
                        width: 65px;
                        margin-right: 15px;
                        background: var(--sidebar-bg);
                        padding: 10px;
                        border-radius: 16px 0px 16px 16px;
                        font-size: 62px;
                        color: var(--primary-color);
                        border: 3px inset var(--sky-blue);

                        @media ($breakpoint_SM) {
                            font-size: 50px;
                        }
                    }
                }
            }
        }

        .right_rank_sec {
            @media ($breakpoint_LG) {
                margin-top: 20px;
            }

            .card {
                background: var(--new-small-inner-card);
                border: 1px solid #dddddd21;
                backdrop-filter: blur(12px);

                @media ($breakpoint_LG) {
                    margin-bottom: 20px;
                }

                .rank_amount {
                    display: flex;
                    justify-content: space-between;
                    background: var(--primary-color);
                    padding: 15px 10px;
                    border: 1px solid var(--bodybg);
                    border-radius: 10px;
                    color: var(--txt-white);
                    margin: 5px 0px 19px 0px;

                    h6 {
                        margin: 0px;
                    }
                }
            }

            .progress_sec {
                p {
                    color: var(--txt-black);
                }

                .progress {
                    margin: 5px 0px;
                    height: 20px;
                }
            }

            .rule {
                p {
                    text-align: center;
                    background: var(--sidebar-bg);
                    padding: 5px;
                    border-radius: 10px;
                    margin: 40px 0px 10px 0px;
                    color: var(--sky-blue);
                }
            }

            .inner_data_values {
                h6 {
                    font-size: 14px;
                    margin-top: 10px;
                    color: var(--txt-black);
                }

                .item-sec {
                    display: flex;
                    justify-content: space-between;
                    background: var(--sidebar-bg);
                    border-radius: 10px;
                    align-items: center;
                    padding: 5px 10px;
                    margin: 10px 0px 0px 0px;
                    color: var(--txt-light);

                    p {
                        margin: 0px;

                    }
                }
            }
        }

    }

    .portfolio_sec {
        padding: 30px 0px 0px 0px;

        .scan_card {
            min-height: 83px;
            border: none;
            background: var(--sidebar-bg);

            @media ($breakpoint_LG) {
                margin-bottom: 20px;
            }

            .card-body {

                align-items: center;



                .link-copy {

                    display: flex;
                    align-items: center;
                    margin-right: 10px;


                    input {
                        font-size: 14px;
                        margin-bottom: 0px;
                        padding: 10px;
                        border-radius: 5px 0 0 5px;
                        width: 100%;
                        border: 1px solid var(--border-color);
                        background: var(--bodybg);
                        color: var(--txt-black);


                    }

                    .icon-copy {
                        background: var(--sky-blue);
                        padding: 5px 11px;
                        border: 1px solid var(--border-color);
                        border-radius: 0 5px 5px 0;
                        border-left: none;
                        cursor: pointer;

                        svg {
                            font-size: 32px;
                            color: var(--txt-white);
                        }
                    }
                }

                .scan_code {
                    width: 38px;
                    border: 1px solid var(--primary-color);
                    border-radius: 5px;
                }

                .icons_im {


                    svg {
                        font-size: 38px;
                        color: var(--primary-color);
                        margin-left: 10px;
                    }
                }
            }
        }

        .rank_card {
            border: none;
            position: relative;
            background: var(--sidebar-bg);

            &::after {
                content: '';
                position: absolute;
                height: 8px;
                transform: rotate(90deg);
                width: 26%;
                top: 46%;
                left: -11%;
                background: var(--primary-color);
                border-radius: 16px 16px 0px 0px;

                @media ($breakpoint_LG) {
                    width: 71px;
                    top: 46%;
                    left: -30px;
                }
            }

            .card-body {
                display: flex;
                justify-content: space-between;
                flex-direction: row-reverse;
                align-items: center;

                .current_rank {
                    padding-left: 10px;

                    .card-title {
                        color: var(--txt-black);
                    }

                    .card-text {
                        color: var(--sky-blue);
                    }
                }

                svg {
                    font-size: 35px;
                    background: var(--light-blue);
                    color: var(--primary-color);
                    padding: 5px;
                    border-radius: 5px;
                }

                img {
                    background: var(--light-blue);
                    padding: 8px;
                    border-radius: 10px;
                    width: 38px;
                }
            }
        }
    }

    .bid_card {

        height: 100%;
        --profit-btn: #0ecb81;
        --loss-btn: #f6465d;

        .react-tabs {

            .react-tabs__tab-list {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: 0;
                gap: 20px;

                .react-tabs__tab {
                    width: 100%;
                    display: block;
                    text-align: center;
                    border: 1px solid #ddd;
                    bottom: 0;
                    border-radius: 10px !important;
                    background-color: #ddd;
                    color: #000;

                    &::after {
                        display: none;
                    }
                }

                .react-tabs__tab--selected {
                    background-color: var(--profit-btn);
                    color: #fff;

                }

                .sell-tabs.react-tabs__tab--selected {
                    background-color: var(--loss-btn);
                }
            }
        }

        h5 {
            color: var(--sky-blue);
            margin-bottom: 20px;
        }

        .bidding_sec {
            margin-bottom: 10px;
.ent-amt {
    border-color: var(--primary-color) !important;
}
            input {
                border: none;
                width: 100%;
                border: 1px solid #ddd;
                background: var(--light-blue);
                border-radius: 5px;
                padding: 8px 10px;
                transition: all 0.3s linear;
                outline: none;
                box-shadow: unset;

                &:focus {
                    border-color: var(--primary-color) !important;

                }
            }
        }

        .buttonsj-group {
            --profit-btn: #0ecb81;
            --loss-btn: #f6465d;

            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            margin-top: 20px;

            .btn {
                width: 100%;
                display: block;
                padding: 9px 13px;
                color: #fff;
                border-radius: 10px;
                transition: all 0.3s linear;
                border: 1px solid transparent;

                &:hover {
                    background-color: transparent !important;
                    color: #000 !important;
                }
            }

            .buy-btn {
                background-color: var(--profit-btn);
                border-color: var(--profit-btn);
            }

            .sell-btn {
                background-color: var(--loss-btn);
                border-color: var(--loss-btn);
            }

        }
    }


    .performance_cards {
        padding: 30px 0px 0px 0px;

        .row {
            row-gap: 15px;
        }





        .order_card {
            height: 100%;

            .card-header {
                padding: 10px;

                h5 {
                    color: var(--sky-blue);

                    margin-bottom: 0 !important;
                }
            }
            .core-btn {
                display: flex;
                align-items: center;
                justify-content: space-between;
                a {
                    padding: 7px 15px;
                    color: var(--txt-black);
                    border-radius: 24px;
                    border: none;
                    font-size: 14px;
                    background: var(--txt-white);
                    outline: none;
                    text-decoration: none;
                    white-space: nowrap;
                
                }
            }
            

            .card-body {
                padding: 0;
                height: 430px;
                overflow-x: hidden;
                overflow-y: scroll;


                @media ($breakpoint_MD) {
                    height: 300px !important;
                }


                .table {
                    thead {
                        position: sticky;
                        top: 0;
                        width: 100%;
                        background: var(--light-blue);
                        z-index: 2;
                        background-color: #fff;

                        th {
                            &:last-child {
                                text-align: center;
                                padding-right: 10px;
                            }
                        }

                    }

                    th,
                    td {
                        // color: #000;
                        padding: 5px 20px;
                        font-weight: normal;
                        white-space: nowrap;
                        text-align: center;

                        @media ($breakpoint_LG) {
                            padding: 5px 10px;
                        }
                    }

                    tbody {
                        tr {
                            position: relative;
                            z-index: 1;

                            td {
                                background-color: transparent !important;

                                &:last-child {
                                    text-align: end;
                                }
                            }
                        }

                        .loss-user {



                            &::after {
                                content: "";
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background-color: #f6475d8c;
                                z-index: -1;
                            }
                        }

                        .profit-user {

                            &::after {
                                content: "";
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background-color: #0dcb8161;
                                z-index: -1;
                            }
                        }
                    }
                }
            }

            .card_auto_hed {
                height: auto !important;

                table {

                    thead {
                        tr {
                            th {
                                border-bottom: 1px solid #ccc !important;
                            }
                        }
                    }
                }



                td {
                    border-color: transparent !important;
                }
            }

        }

        .card_auto_hed_d{
            border: 0;
            border-radius: 20px;
            padding: 20px;
            background-color: var(--primary-color);

            .card-header{
                background-color: transparent;
                padding: 15px 10px;
                border-bottom: 0.5px solid #ccc;
                margin-bottom: 10px;

                h5{
                    color: var(--txt-white) !important;
                }
            }

            .card-body{
                table{
                    thead{
                        background-color: transparent !important;

                        th{
                            background-color: transparent;
                            color: var(--txt-white) !important;
                        }
                    }
                    tbody{
                        td{
                            color: var(--txt-white);
                        }
                    }
                }
            }
        }

        .nw-cards {
            background-color: var(--txt-white);
            .nw-cards1 {
                background-color: var(--primary-color);
                border-radius: 7px;
            }

        }
        .back-card {
            background: var(--gradient);
            border-radius: 30px;
            color: var(--txt-black);
        }
        .back-clr {
            color: var(--txt-black) !important;
            text-align: center !important;
        }

        .left_card {
            background: var(--sidebar-bg);
            position: relative;
            z-index: -3;
            border-left: 2px solid var(--primary-color) !important;
            border: none;
            height: 100%;

            // &::after {
            //     content: "";
            //     position: absolute;
            //     height: 100%;

            //     width: 100%;
            //     background-image: url(../Image/profit.png);
            //     z-index: -1;
            //     border-radius: 6px;
            //     background-size: contain;
            //     background-position: right;
            //     opacity: 0.2;
            //     background-repeat: no-repeat;

            // }

            .card-body {
                h5 {
                    color: var(--primary-color);
                    margin-bottom: 15px;
                }

                .list_sec {
                    display: flex;
                    justify-content: space-between;

                    p {
                        color: var(--txt-black);
                    }

                    .percentage {
                        background: var(--light-blue);
                        padding: 5px;
                        border-radius: 5px;
                    }

                    p {
                        svg {
                            color: var(--txt-white);
                            padding: 5px;
                            background: var(--primary-color);
                            border-radius: 5px;
                            font-size: 30px;
                        }
                    }
                }
            }
        }

        .right_sec {
            border-left: 2px solid var(--primary-color) !important;
            border: none;
            background: var(--sidebar-bg);

            .card-body {
                h5 {
                    color: var(--primary-color);
                }

                .inner_img_sec {
                    padding: 17px 0px;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    .img_icons {
                        display: flex;
                        align-items: center;
                        gap: 5px;

                        p {
                            color: var(--txt-black);
                        }

                        img {
                            width: 38px;
                            padding: 5px;
                            transition: all 0.4s linear;

                            &:hover {
                                transform: scale(1.2);
                            }
                        }

                        p {
                            margin: 0px;
                        }
                    }
                }

                .profit_month {
                    display: flex;
                    justify-content: space-between;
                    padding: 10px;
                    background: var(--bodybg);
                    border-radius: 10px;


                    p {
                        margin-bottom: 0px;
                        color: var(--txt-black);
                    }
                }
            }
        }
    }

    .chart_sections {
        padding: 30px 0px 0px 0px;

        .chart_style svg g text {
            fill: #000 !important
        }

        // .chart_style {
        //     background-color: var(--bodybg);
        // }
    }

    @include table_styles;
}

.qr_scan_modal {
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    padding: 20px !important;
    background-color: var(--sidebar-bg) !important;

    .scan_modal {
        width: 200px;

        border-radius: 5px;
    }

    .react-responsive-modal-closeButton {
        display: none;
    }
}

.homepage_modal {
    display: none !important;
    border-radius: 10px;
    border: 1px solid var(--border-color) !important;
    box-shadow: var(--box-shadow) !important;
    background: var(--sidebar-bg) !important;

    .banner_img {
        width: 100%;
        border-radius: 10px;
    }

    .react-responsive-modal-closeButton {
        display: none;
    }


}



.new-bar {
    background-color: #f0f0f3;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;

    .range-slider_thumb {
        color: var(--txt-black);
        padding: 10px;
        font-size: 14px;
        height: 40px;
        font-size: 13px;

    }

    .bar-out {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
    }

    input {
        width: 100%;
    }


    /* Chrome & Safari */
    input[type="range"]::-webkit-slider-runnable-track {
        appearance: none !important;
        background-color: transparent;
        border-radius: 4px;
        width: 100%;
        height: 25px;
        cursor: pointer;
    }

    input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        margin-top: 0;
        cursor: pointer;
        transform: scale(1.6);
        margin-top: 4px;
    }
}


// .new-renge-sdc {

//     .range-slider {
//         position: relative;
//         width: 80vmin;
//         height: 20vmin;
//     }

//     .range-slider_input {
//         width: 100%;
//         position: absolute;
//         top: 50%;
//         z-index: 3;
//         transform: translateY(-50%);
//         -webkit-appearance: none;
//         appearance: none;
//         width: 100%;
//         height: 4px;
//         opacity: 0;
//         margin: 0;
//     }

//     .range-slider_input::-webkit-slider-thumb {
//         -webkit-appearance: none;
//         appearance: none;
//         width: 100px;
//         height: 100px;
//         cursor: pointer;
//         border-radius: 50%;
//         opacity: 0;
//     }

//     .range-slider_input::-moz-range-thumb {
//         width: 14vmin;
//         height: 14vmin;
//         cursor: pointer;
//         border-radius: 50%;
//         opacity: 0;
//     }

//     .range-slider_thumb {
//         width: 14vmin;
//         height: 14vmin;
//         border: 0.6vmin solid #303030;
//         border-radius: 50%;
//         position: absolute;
//         left: 0;
//         top: 50%;
//         transform: translateY(-50%);
//         background-color: #f4f4f4;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         font-weight: 700;
//         font-size: 4vmin;
//         color: #303030;
//         z-index: 2;
//     }

//     .range-slider_line {
//         height: 0.5vmin;
//         width: 100%;
//         background-color: #e1e1e1;
//         top: 50%;
//         transform: translateY(-50%);
//         left: 0;
//         position: absolute;
//         z-index: 1;
//     }

//     .range-slider_line-fill {
//         position: absolute;
//         height: 0.5vmin;
//         width: 0;
//         background-color: #303030;
//     }

// }

.stake_modal {
    border-radius: 10px;
    width: 350px;

    @media ($breakpoint_450) {
        width: 270px !important;
    }

    .vista_sec {
        h5 {
            color: var(--sky-blue);
            text-align: center;
            margin-bottom: 20px;

        }

        p {
            color: var(--txt-black);
        }

        input {
            width: 100%;
            border: none;
            background: var(--light-blue);
            padding: 10px;
            border-radius: 10px;
            margin-bottom: 10px;
            text-transform: capitalize;

            &:focus {
                outline: none;
            }

        }

        .stake_btn {
            margin-top: 20px;
            text-align: center;
        }
    }
}

.total-limit-sec-inner {
    @media ($breakpoint_LG) {
        display: none;
    }
}

.total-limit-sec-outer {
    display: none;

    @media ($breakpoint_LG) {
        display: block;
    }
}

.investment-card {
    height: 100%;

    .card-body {
        padding: 0;
    }

    .table_section {
        padding: 0;
    }
}
.congra{
    font-size: 20px;
    font-family: emoji;
    font-weight: 600;
    margin: 0;
}
.modal-header{
    border: none;
}
.modal-footer{
    border: none;
    justify-content: center;
}
.open-form{
    text-decoration: none;
    background:var(--primary-color);
    color:var(--txt-white);
    padding: 10px 20px;
    border-radius: 7px;
    border: 1px solid var(--primary-color);
    transition: all 0.3s linear;
    font-weight: 600;
    &:hover{
        background: none;
        color: var(--primary-color);

    }
}





.open-form{
    background: #2e5a76;
    color: var(--txt-white);
    border: 1px solid #2e5a76;
    &:hover{
        background: none;
        color: black !important;
    }
}
.cong-h5 {
    text-align: center;
    width: 100%;
}
.a-with {
    background-color: var(--txt-white);
    color: var(--sky-blue);
    text-decoration: none;
    font-size: 14px;
    border: noneq;
    border-radius: 24px;
    padding: 7px 15px;
    outline: 3px inset var(--sky-blue);
}